<template>
  <v-dialog v-model="dispositionHistoryDialog" persistent max-width="850">
    <v-card v-if="dispositionHistory">
      <v-card-title class="headline">
        {{ $t("card.memo-disposition-history") }}
      </v-card-title>
      <v-card-text class="pa-2">
        <v-tabs v-model="tab" color="primary accent-4">
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1">
            <v-icon class="mr-2">{{ iconDisposition }}</v-icon>
            {{ $t("tab.delegation-info") }}
          </v-tab>

          <v-tab href="#tab-2">
            <v-icon class="mr-2">{{ iconTracking }}</v-icon>
            {{ $t("tab.tracking-info") }}
          </v-tab>

          <v-tab href="#tab-3">
            <v-icon class="mr-2">{{ iconFeedback }}</v-icon>
            {{ $t("tab.feedback") }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item value="tab-1">
            <v-card flat class="no-box-shadow table-responsive-container">
              <v-card-text class="table-responsive"
                ><p
                  class="mb-2 text--bold"
                  v-html="$t('label.to-whom-this-memo-has-dispositioned')"
                ></p>
                <table width="100%" class="table-align-top">
                  <tr>
                    <td width="20%">{{ $t("label.sending-at") }}</td>
                    <td width="1%">:</td>
                    <td width="79%" style="cursor: pointer">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-on="on" v-bind="attrs">{{
                            $date(dispositionHistory.created_at).format(
                              "DD MMMM YYYY HH:mm WIB"
                            )
                          }}</span>
                        </template>
                        <span>{{
                          timeAgo(dispositionHistory.created_at)
                        }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("label.disposition-recipients") }}</td>
                    <td>:</td>
                    <td>
                      <ol class="pl-4">
                        <li
                          v-for="item in dispositionHistory.recipients"
                          :key="item.id"
                        >
                          <strong>{{ item.nama }}</strong>
                          <span>&nbsp;({{ item.nama_jabatan }})</span>
                          <v-tooltip bottom v-if="item.readed_at">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-on="on"
                                v-bind="attrs"
                                class="success--text"
                                >{{ iconEye }}</v-icon
                              >
                            </template>
                            <span>{{
                              $t("tooltip.has-read-by-disposition-recipient")
                            }}</span>
                          </v-tooltip>
                          <v-tooltip bottom v-if="parseInt(item.is_pic) === 1">
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-on="on"
                                v-bind="attrs"
                                style="
                                  height: 16px;
                                  width: 16px;
                                  position: absolute;
                                "
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style="height: 22px"
                                >
                                  <g
                                    id="SVGRepo_bgCarrier"
                                    stroke-width="0"
                                  ></g>
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></g>
                                  <g id="SVGRepo_iconCarrier">
                                    <path
                                      d="M21.12 9.88005C21.0781 9.74719 20.9996 9.62884 20.8935 9.53862C20.7873 9.4484 20.6579 9.38997 20.52 9.37005L15.1 8.58005L12.67 3.67005C12.6008 3.55403 12.5027 3.45795 12.3853 3.39123C12.2678 3.32451 12.1351 3.28943 12 3.28943C11.8649 3.28943 11.7322 3.32451 11.6147 3.39123C11.4973 3.45795 11.3991 3.55403 11.33 3.67005L8.89999 8.58005L3.47999 9.37005C3.34211 9.38997 3.21266 9.4484 3.10652 9.53862C3.00038 9.62884 2.92186 9.74719 2.87999 9.88005C2.83529 10.0124 2.82846 10.1547 2.86027 10.2907C2.89207 10.4268 2.96124 10.5512 3.05999 10.6501L6.99999 14.4701L6.06999 19.8701C6.04642 20.0091 6.06199 20.1519 6.11497 20.2826C6.16796 20.4133 6.25625 20.5267 6.36999 20.6101C6.48391 20.6912 6.61825 20.7389 6.75785 20.7478C6.89746 20.7566 7.03675 20.7262 7.15999 20.6601L12 18.1101L16.85 20.6601C16.9573 20.7189 17.0776 20.7499 17.2 20.7501C17.3573 20.7482 17.5105 20.6995 17.64 20.6101C17.7537 20.5267 17.842 20.4133 17.895 20.2826C17.948 20.1519 17.9636 20.0091 17.94 19.8701L17 14.4701L20.93 10.6501C21.0305 10.5523 21.1015 10.4283 21.1351 10.2922C21.1687 10.1561 21.1634 10.0133 21.12 9.88005Z"
                                      fill="#17deb6"
                                    ></path>
                                  </g>
                                </svg>
                              </span>
                            </template>
                            <span>{{ $t("tooltip.this person is pic") }}</span>
                          </v-tooltip>
                        </li>
                      </ol>
                    </td>
                  </tr>
                </table>
                <p class="mb-2 mt-8 text--bold">
                  {{ $t("label.disposition-selected-message") }}
                </p>
                <table width="100%" class="table-align-top">
                  <tr>
                    <td width="20%">{{ $t("label.urgency-level") }}</td>
                    <td width="1%">:</td>
                    <td width="79%">
                      {{ urgencyLevel(dispositionHistory.urgensi) }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("label.disposition-messages") }}</td>
                    <td>:</td>
                    <td style="cursor: pointer">
                      <ul
                        style="list-style-type: none"
                        class="pl-0 history--list-checkbox"
                      >
                        <li
                          v-for="(item, idx) in dispositionHistory.messages"
                          :key="idx"
                        >
                          <v-icon class="mr-2">{{ iconCheck }}</v-icon
                          ><span>{{ item }}</span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("label.additional-comment") }}</td>
                    <td>:</td>
                    <td>{{ dispositionHistory.comment }}</td>
                  </tr>
                </table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <v-card flat class="no-box-shadow">
              <v-card-text>{{
                $t("label.where-the-disposition-now")
              }}</v-card-text>
              <tracking-disposition></tracking-disposition>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-3">
            <v-card flat class="no-box-shadow">
              <v-card-text
                style="overflow-x: auto"
                v-if="dispositionFeedbacks && dispositionFeedbacks.length > 0"
              >
                <p>
                  {{ $t("label.feedback-if-done") }}
                </p>
                <table border="1" class="table__permit-history" width="100%">
                  <tr>
                    <th class="px-1 py-1">
                      {{ $t("text.disposition-recipient") }}
                    </th>
                    <th class="px-1 py-1">{{ $t("text.feedback-comment") }}</th>
                  </tr>
                  <tr v-for="item in dispositionFeedbacks" :key="item.nik">
                    <td class="px-1 py-1 text-justify" style="cursor: pointer">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-on="on" v-bind="attrs">{{
                            item.creator
                          }}</span>
                        </template>
                        <span>{{
                          $t("tooltip.dispositioned-by") +
                          " " +
                          item.atasan_langsung
                        }}</span>
                      </v-tooltip>
                      
                    </td>
                    <td class="px-1 py-1 text-justify" style="cursor: pointer">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-on="on" v-bind="attrs">{{
                            item.feedback
                          }}</span>
                        </template>
                        <span>{{
                          $t("tooltip.feedback-at") +
                          " " +
                          $date(item.updated_at).fromNow()
                        }}</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="item.file_path">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="ml-2"
                      color="blue"
                      v-on:click="downloadAttachment('view',item)"
                      v-on="on"
                      v-bind="attrs"
                    >
                      <v-icon>{{ iconPaperClip }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("tooltip.show attachment") }}</span>
                </v-tooltip>
                    </td>
                  </tr>
                </table>
              </v-card-text>
              <v-card-text v-else
                ><p class="text-center py-2">
                  {{ $t("text.feedback-is-empty") }}
                </p></v-card-text
              >
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="red darken-1"
          @click="dispositionHistoryDialog = false"
        >
          <v-icon class="mr-2">{{ iconClose }}</v-icon>
          {{ $t("button.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-else>
      <v-skeleton-loader
        class="mx-auto"
        max-width="100%"
        type="article, actions"
      ></v-skeleton-loader>
    </v-card>
    <v-overlay v-bind:value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  mdiAccountArrowRightOutline,
  mdiMapSearchOutline,
  mdiCommentQuoteOutline,
  mdiCheck,
  mdiEyeOutline,
  mdiClose,
  mdiPaperclip,
} from "@mdi/js";
import TrackingDisposition from "./detailComponents/TrackingDisposition.vue";
import { http } from "@/http/http";

export default {
  name: "disposition-history-modal",
  components: {
    TrackingDisposition,
  },
  props: {
    memoId: { type: String },
    dispositionId: { type: String },
  },
  data: () => ({
    overlay: false,
    iconDisposition: mdiAccountArrowRightOutline,
    iconTracking: mdiMapSearchOutline,
    iconFeedback: mdiCommentQuoteOutline,
    iconCheck: mdiCheck,
    iconEye: mdiEyeOutline,
    iconClose: mdiClose,
    iconPaperClip: mdiPaperclip,
    dispositionRecipients: [],
    tab: 0,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  }),
  computed: {
    ...mapState("inbox", ["dispositionHistoryDetail"]),
    ...mapState("disposition", ["dispositionFeedbacks"]),
    ...mapState(["networkError"]),
    dispositionHistory() {
      if (!this.dispositionHistoryDetail) return undefined;
      return this.dispositionHistoryDetail;
    },
    // dispositionId() {
    //   if (!this.delegationHistory || !this.delegationHistory[0] ) return null;
    //   return this.delegationHistory[0].id_hash;
    // },
    dispositionHistoryDialog: {
      get() {
        return this.$store.state.inbox.dispositionHistoryDialog;
      },
      set(val) {
        this.$store.commit("inbox/SET_DISPOSITION_HISTORY_DIALOG", val);
      },
    },
  },
  watch: {
    dispositionHistoryDialog(val) {
      if (!val || !this.dispositionId || !this.memoId) return;
      this.loadDispositionDetail();
      this.tab = 0;
    },
    memoId(val) {
      if (!val || !this.dispositionId || !this.dispositionHistoryDialog) return;
      this.loadDispositionDetail();
    },
  },
  methods: {
    ...mapActions("inbox", [
      "getDispositionDetail",
      "setDispositionHistoryDialog",
    ]),
    ...mapActions("disposition", [
      "getDispositionTrack",
      "getDispositionFeedbacks",
    ]),
    loadDispositionDetail() {
      this.getDispositionDetail({
        memoId: this.memoId,
        dispositionId: this.dispositionId,
      });
      this.getDispositionTrack({ memoId: this.dispositionId });
      this.getDispositionFeedbacks({
        memoId: this.memoId,
        dispositionId: this.dispositionId,
      });
    },
    urgencyLevel(val) {
      let intVal = parseInt(val);
      let result = "direct";
      switch (intVal) {
        case 1:
          result = "normal";
          break;
        case 2:
          result = "urgent";
          break;
        case 3:
          result = "very-urgent";
          break;
        default:
          result = "normal";
          break;
      }
      return this.$t("label." + result);
    },
    downloadAttachment(mode, params){
      if (!params) return;
      // api/memo/{id}/attachments/{attachmentId}
      const options = {
        responseType: "blob",
      };

      this.updateOverlay(true);
      http.get(`/memo/${this.memoId}/feedback-attachment?file_path=${params.file_path}`, options)
        .then((response) => {
          if (response.status === 500) return this.updateOverlay(false);
          let arrImageType = ["image/jpeg", "/image/png"];
          if (response) {
            this.updateOverlay(false);
            if (mode === "download") {
              let dfileURL = window.URL.createObjectURL(new Blob([response]));
              let dfileLink = document.createElement("a");

              dfileLink.href = dfileURL;
              dfileLink.setAttribute("download", params.file_name);
              document.body.appendChild(dfileLink);

              dfileLink.click();
            } else {
              if (arrImageType.includes(params.file_type)) {
                let file = new File([response], "Download.png", {
                  type: "image/png",
                });
                var fileURL = URL.createObjectURL(file);
              } else if (params.file_type === "application/pdf") {
                var file = new Blob([response], {
                  type: "application/pdf;base64",
                });
                fileURL = URL.createObjectURL(file);
              }
              window.open(fileURL);
            }
          }
        });
    },
    updateOverlay(val) {
        this.overlay = val;
      },
  },
};
</script>
<style lang="scss">
.no-shadow {
  box-shadow: none !important;
}
</style>
